import './App.css';
import { useState } from 'react';
import background from './background.png';

declare var ZoomMtg

ZoomMtg.setZoomJSLib('https://source.zoom.us/1.9.6/lib', '/av');

ZoomMtg.preLoadWasm();
ZoomMtg.prepareJssdk();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load('en-US');
ZoomMtg.i18n.reload('en-US');

function App() {
  const [userName, setUsername] = useState('')
  const [passWord, setPassWord] = useState('')
  // setup your signature endpoint here: https://github.com/zoom/websdk-sample-signature-node.js
  var signatureEndpoint = process.env.REACT_APP_SIGNATURE_ENDPOINT
  var apiKey = process.env.REACT_APP_ZOOM_API_KEY
  var meetingNumber = process.env.REACT_APP_YOUYOU_MEETING_NUMBER
  var role = 0
  var leaveUrl = 'https://youyou.family/'
  var userEmail = ''

  function getSignature(e) {
    e.preventDefault();

    fetch(signatureEndpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        meetingNumber: meetingNumber,
        role: role
      })
    }).then(res => res.json())
    .then(response => {
      startMeeting(response.signature)
    }).catch(error => {
      console.error(error)
    })
  }

  function startMeeting(signature) {
    document.getElementById('zmmtg-root').style.display = 'block'

    ZoomMtg.init({
      leaveUrl: leaveUrl,
      isSupportAV: true,
      success: (success) => {
        console.log(success)

        ZoomMtg.join({
          signature: signature,
          meetingNumber: meetingNumber,
          userName: userName,
          apiKey: apiKey,
          userEmail: userEmail,
          passWord: passWord,
          success: (success) => {
            console.log(success)
          },
          error: (error) => {
            console.log(error)
          }
        })

      },
      error: (error) => {
        console.log(error)
      }
    })
  }
  const handleUsernameChange = (e) => {
    setUsername(u => e.target.value)
  }

  const handlePasswordChange = (e) => {
    setPassWord(u => e.target.value)
  }

  return (
    <div className="App" style={{backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%'}}>
      <main style={{backgroundColor: 'rgb(255,255,255,0.8)'}}>
        <h1>Meet YouYou!</h1>
        <strong>Name: </strong><br />
        <input type='text' onChange={handleUsernameChange}></input><br />
        {/* <strong>Meeting Password: </strong><br />
        <input type='text' onChange={handlePasswordChange}></input><br /> */}
        <button onClick={getSignature}>Join Meeting</button>
      </main>
    </div>
  );
}

export default App;
